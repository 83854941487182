import React, { useState } from "react"

import { Link } from "gatsby"
import PropTypes, { element } from "prop-types"
import { colors } from "../utils"

import Logo from "../svg/logo.svg"
//import Background from '../svg/header.svg';
import { FaBars } from 'react-icons/fa'

const Header = ({ dims, scroll, height }) => {
  const [showMenu, setShowMenu] = useState(false)

  const styles = {
    bg: {
      position: "absolute",
      zIndex: -1,
    },
    container: {
      display: "flex",
      width: "100%",
      maxWidth: 1200,
      flexDirection: "row-reverse",
      justifyContent: "space-between",
      paddingTop: dims.x > 1200 ? "2%" : 10,
    },
    list: {
      display: "flex",
      flexDirection: dims.x > 600 ? "row" : "column",
      justifyContent: "flex-end",
      alignItems: "flex-end",
      listStyleType: "none",
      marginRight: 10,
    },
    link: {
      color: colors.dark_blue,
      textDecoration: `none`,
      paddingLeft: 30,
      paddingRight: showMenu ? 0 : 5,
      fontWeight: "bold",
      fontFamily: "sans-serif",
      marginRight: dims.x > 600 ? 0 : 10,
      marginVertical: 5,
      paddingTop: 5,
      paddingBottom: dims.x > 600 ? 5 : 9,
    },
  }

  return (
    <header
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        position: scroll > 100 ? "fixed" : "absolute",
        top: 0,
        backgroundColor: dims.x > 1200 ? {} : colors.white,
        zIndex: scroll > 100 || showMenu ? 503 : 0,
        height: showMenu ? 230 : 90,
      }}
    >
      {dims.x > 600 && <div style={styles.bg}></div>}
      <div style={styles.container}>
        <div
          style={{
            display: "flex",
            flex: 5,
            flexDirection: showMenu ? "column" : "row",
            alignItems: "space-between",
            justifyContent: "flex-end",
            marginTop: 25,
            backgroundColor: "#fff0",
            height: dims.x > 600 ? 40 : 80,
          }}
        >
          {scroll > 100 && (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Link
                to="/"
                label="home"
                style={{
                  position: "absolute",
                  left: -10,
                  top: -15,
                  transform: "scale(0.7)",
                }}
              >
                <Logo />
              </Link>
              {dims.x > 600 && (
                <a
                  href="tel:+33644769178"
                  style={{
                    position: "absolute",
                    left: 150,
                    top: 30,
                    color: colors.dark_blue,
                    fontFamily: "sans-serif",
                    fontWeight: "semi-bold",
                    fontSize: 25,
                    textDecoration: "none",
                  }}
                >
                  06 44 76 91 78
                </a>
              )}
            </div>
          )}
          {dims.x < 600 && !showMenu && (
            <FaBars
              onClick={() => setShowMenu(!showMenu)}
              size={30}
              color={colors.blue}
              style={{ position: "relative", right: 15, bottom: 25 }}
            />
          )}
          {(dims.x > 600 || showMenu) && (
            <ul
              style={{
                ...styles.list,
                marginBottom: showMenu ? -100 : 17,
                marginTop: dims.x < 1200 && dims.x > 600 ? 35 : {},
              }}
            >
              {dims.x < 600 && (
                <li>
                  <FaBars
                    onClick={() => setShowMenu(!showMenu)}
                    size={30}
                    color={colors.blue}
                    style={{ position: "relative", top: 5, right: 5.5 }}
                  />
                </li>
              )}
              <li>
                <Link
                  to="/"
                  style={{
                    ...styles.link /*backgroundColor: parentPage ? "#bbb" : "#ddd", borderBottomLeftRadius: dims.x <= 1200 ? 0 : 5, borderTopRightRadius: dims.x <= 1200 ? 5 : 0, borderTopLeftRadius: 5, paddingTop: 10, width: dims.x > 1200 ? 'auto' : '50%'*/,
                  }}
                >
                  Parents
                </Link>
              </li>
              <li>
                <Link
                  to="/teachers"
                  style={{
                    ...styles.link /*backgroundColor: parentPage ? "#ddd" : "#bbb", borderTopRightRadius: dims.x <= 1200 ? 0 : 5, borderBottomRightRadius: 5, borderBottomLeftRadius: dims.x <= 1200 ? 5 : 0, paddingTop: 10*/,
                  }}
                >
                  Devenir professeur
                </Link>
              </li>
              <li style={{ marginTop: 15 }}>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://app.expliquemoipythagore.fr"
                  style={{
                    color: "white",
                    textDecoration: `none`,
                    paddingLeft: 20,
                    paddingRight: 20,
                    paddingTop: 15,
                    paddingBottom: 15,
                    fontWeight: "bold",
                    fontFamily: "sans-serif",
                    marginRight: 0,
                    marginLeft: 10,
                    borderRadius: 5,
                    backgroundColor: colors.medium_blue,
                  }}
                >
                  Connexion / Inscription
                </a>
              </li>
              {dims.x < 600 && (
                <li style={{ marginTop: 15, marginRight: 5, marginBottom: 5 }}>
                  {/*<FaIcons name="phone" aria-hidden="true" />*/}
                  <a
                    href="tel:+33644769178"
                    style={{
                      color: colors.dark_blue,
                      fontFamily: "sans-serif",
                      fontWeight: "bold",
                      fontSize: 17,
                      textDecoration: "none",
                    }}
                  >
                    06 44 76 91 78
                  </a>
                </li>
              )}
            </ul>
          )}
        </div>
      </div>
      {/*scroll > 100 &&
      <progress style={{position: 'absolute', width: "100%", top: showMenu ? '97%' : '95%', height: 10}}  value={height} max={height-dims.y}/>
      */}
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
