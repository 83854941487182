export const colors = {
  "dark_blue": "#002d6d",
  "medium_blue": "#003d8d",
  "button_blue": "#0d4ed9",
  "blue": "#4080ff",
  "bg": "#f5f5f7",
  "error": "#e02d2d",
  "orange": "#ff962e",
  "yellow": "#ffc700",
  "green": "#35b773",
  "gray": "#939393",
  "validated": "#23d4ac",
  "black": "#000000",
  "white": "#ffffff",
  "skin": "#ffbb94",
  "darkSkin": "#ee8360",
}
