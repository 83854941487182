import React from 'react'

import { colors } from '../utils'
import Fond10 from '../svg/fond_10.svg'
import { Link } from 'gatsby'

import Logo from '../svg/logo.svg'
import cgu from '../pdf/CGU_2022.pdf'
import cgv from '../pdf/CGV_2022.pdf'
import mediateur from '../pdf/convention_CM2C.pdf'

export default ({ dims }) => {
  return (
    <div
      style={{
        width: '100%',
        position: 'relative',
        display: 'flex',
        flexDirection: dims.x > 1200 ? 'row' : 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: dims.x > 1200 ? 80 : 0,
        backgroundColor: dims.x > 1200 ? 'transparent' : colors.dark_blue,
      }}
    >
      {dims.x > 1200 && (
        <div style={{ position: 'absolute', top: 0, left: 0, width: '100%' }}>
          <Fond10 />
          <div style={{ width: '100%', height: 20, backgroundColor: '#082144', marginTop: -20 }} />
        </div>
      )}
      <div
        style={{
          width: dims.x > 1200 ? '100%' : '80%',
          maxWidth: 1200,
          position: 'relative',
          display: 'flex',
          flexDirection: dims.x > 1200 ? 'row' : 'column',
          alignItems: dims.x > 1200 ? 'flex-start' : 'flex-start',
          justifyContent: dims.x > 1200 ? 'space-between' : 'flex-start',
          marginTop: dims.x > 1200 ? 250 : 0,
        }}
      >
        {dims.x > 1200 && <Logo />}
        <div>
          <h3
            style={{
              textTransform: 'uppercase',
              color: colors.white,
              fontFamily: 'Poppins',
              fontSize: 18,
              margin: 0,
              marginBottom: 20,
              marginTop: dims.x > 1200 ? 0 : 50,
            }}
          >
            Nous contacter
          </h3>
          <h4
            style={{
              fontSize: 14,
              color: colors.blue,
              fontFamily: 'Poppins',
              margin: 0,
              marginTop: 10,
              fontWeight: 'normal',
              textTransform: 'uppercase',
            }}
          >
            Adresse email
          </h4>
          <div>
            <a
              rel="noreferrer"
              href="mailto:expliquemoipythagore@gmail.com"
              style={{ textDecoration: 'none', fontSize: 14, color: colors.blue, fontFamily: 'Poppins', margin: 0 }}
            >
              contact@expliquemoipythagore.fr
            </a>
          </div>
          <h4
            style={{
              fontSize: 14,
              color: colors.blue,
              fontFamily: 'Poppins',
              margin: 0,
              marginTop: 10,
              fontWeight: 'normal',
              textTransform: 'uppercase',
            }}
          >
            Téléphone
          </h4>
          <div>
            <a
              rel="noreferrer"
              href="tel:+33644769178"
              style={{ textDecoration: 'none', fontSize: 14, color: colors.blue, fontFamily: 'Poppins', margin: 0 }}
            >
              +33 6 44 76 91 78
            </a>
          </div>
        </div>
        <div>
          <h3
            style={{
              textTransform: 'uppercase',
              color: colors.white,
              fontFamily: 'Poppins',
              fontSize: 18,
              margin: 0,
              marginBottom: 20,
              marginTop: dims.x > 1200 ? 0 : 50,
            }}
          >
            Rejoignez-nous
          </h3>
          <div>
            <a
              rel="noreferrer"
              href="https://app.expliquemoipythagore.fr/register"
              style={{ fontSize: 14, color: colors.blue, fontFamily: 'Poppins', margin: 0, textDecoration: 'none' }}
            >
              Parent / Elève
            </a>
          </div>
          <div>
            <a
              rel="noreferrer"
              href="https://forms.gle/C67VWCN22G87y6j87"
              style={{ fontSize: 14, color: colors.blue, fontFamily: 'Poppins', margin: 0, textDecoration: 'none' }}
            >
              Professeur
            </a>
          </div>
        </div>
        <div>
          <h3
            style={{
              textTransform: 'uppercase',
              color: colors.white,
              fontFamily: 'Poppins',
              fontSize: 18,
              margin: 0,
              marginBottom: 20,
              marginTop: dims.x > 1200 ? 0 : 50,
            }}
          >
            à propos
          </h3>
          <div>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://blog.expliquemoipythagore.fr"
              style={{ fontSize: 14, color: colors.blue, fontFamily: 'Poppins', margin: 0, textDecoration: 'none' }}
            >
              Qui sommes-nous ?
            </a>
          </div>
          <div>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://blog.expliquemoipythagore.fr"
              style={{ fontSize: 14, color: colors.blue, fontFamily: 'Poppins', margin: 0, textDecoration: 'none' }}
            >
              On parle de nous
            </a>
          </div>
          <div>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://blog.expliquemoipythagore.fr"
              style={{ fontSize: 14, color: colors.blue, fontFamily: 'Poppins', margin: 0, textDecoration: 'none' }}
            >
              Notre blog
            </a>
          </div>
        </div>
        <div>
          <h3
            style={{
              textTransform: 'uppercase',
              color: colors.white,
              fontFamily: 'Poppins',
              fontSize: 18,
              margin: 0,
              marginBottom: 20,
              marginTop: dims.x > 1200 ? 0 : 50,
            }}
          >
            Mentions légales
          </h3>
          <div style={{ fontSize: 14, color: colors.blue, fontFamily: 'Poppins', margin: 0, textDecoration: 'none' }}>
            <a
              style={{ fontSize: 14, color: colors.blue, fontFamily: 'Poppins', margin: 0, textDecoration: 'none' }}
              rel="noreferrer"
              target="_blank"
              href={cgu}
            >
              CGU
            </a>
            {' / '}
            <a
              style={{ fontSize: 14, color: colors.blue, fontFamily: 'Poppins', margin: 0, textDecoration: 'none' }}
              rel="noreferrer"
              target="_blank"
              href={cgv}
            >
              CGV
            </a>
          </div>
          <div>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://app.expliquemoipythagore.fr/rgpd"
              style={{ fontSize: 14, color: colors.blue, fontFamily: 'Poppins', margin: 0, textDecoration: 'none' }}
            >
              RGPD
            </a>
          </div>
          <div>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://blog.expliquemoipythagore.fr/wp-content/uploads/2020/08/NOTRE-POLITIQUE-DE-PROTECTION-DES-DONNEES-A-CARACTERE-PERSONNEL.pdf"
              style={{ fontSize: 14, color: colors.blue, fontFamily: 'Poppins', margin: 0, textDecoration: 'none' }}
            >
              Mentions légales
            </a>
          </div>
          <Link
            to="/privacy"
            style={{ fontSize: 14, color: colors.blue, fontFamily: 'Poppins', margin: 0, textDecoration: 'none' }}
          >
            Politique de confidentialité
          </Link>
          <div>
            <a
              rel="noreferrer"
              target="_blank"
              href={mediateur}
              style={{ fontSize: 14, color: colors.blue, fontFamily: 'Poppins', margin: 0, textDecoration: 'none' }}
            >
              Médiateur
            </a>
          </div>
        </div>
      </div>
      {!(dims.x > 1200) && (
        <div style={{ width: '60%', display: 'flex', justifyContent: 'center', marginTop: 30, marginBottom: 30 }}>
          <Logo />
        </div>
      )}
    </div>
  )
}
